define("discourse/plugins/discourse-custom-bbcodes/discourse/initializers/apply-notice", ["exports", "discourse/lib/plugin-api", "discourse/controllers/composer"], function (_exports, _pluginApi, _composer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializeNotice(api) {
    const enabledTags = api.container.lookup('site-settings:main').custom_bbcodes_list.split('|');
    if (enabledTags.indexOf('notice') === -1) return;
    api.decorateCooked($elem => {
      $elem.find('blockquote.notice').each(function () {
        let notice = $(this);
        let emoji = notice.children(0).children('img.emoji').first();
        if (emoji.is('img.emoji')) {
          let type = emoji.attr('title');
          if (typeof type === 'string') {
            type = type.replace(/^:|:$/gm, '');
            notice.removeClass(`notice-${type}`);
            notice.addClass(`notice-${type}`);
            emoji.removeClass(`only-emoji`);
            emoji.addClass(`notice-icon`);
          }
        }
      });
    }, {
      id: "discourse-custom-bbcodes-notice"
    });
    api.addToolbarPopupMenuOptionsCallback(() => ({
      action: 'insertNotice',
      icon: 'caret-right',
      label: 'notice.title'
    }));
    _composer.default.reopen({
      actions: {
        insertNotice() {
          this.get("toolbarEvent").applySurround("[notice]", "[/notice]", "insert_notice");
        }
      }
    });
  }
  var _default = _exports.default = {
    name: "custom-bbcodes-apply-notice",
    initialize() {
      (0, _pluginApi.withPluginApi)('0.5', initializeNotice);
    }
  };
});