define("discourse/plugins/discourse-custom-bbcodes/discourse/initializers/apply-kbd", ["exports", "discourse/lib/plugin-api", "discourse/controllers/composer"], function (_exports, _pluginApi, _composer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializeKbd(api) {
    const enabledTags = api.container.lookup('site-settings:main').custom_bbcodes_list.split('|');
    if (enabledTags.indexOf('kbd') === -1) return;

    // TODO Dedicated tooltip?
    // api.decorateCooked(elem => {
    //   $elem.find('.keyseq').hover(function() {

    //   });
    // }, { id: "discourse-custom-bbcodes-kbd" });

    api.addToolbarPopupMenuOptionsCallback(() => ({
      action: 'insertKbd',
      icon: 'keyboard',
      label: 'kbd.title'
    }));
    _composer.default.reopen({
      actions: {
        insertNotice() {
          this.get("toolbarEvent").applySurround("[kbd command=some-emacs-command]", "[/kbd]", "insert_kbd");
        }
      }
    });
  }
  var _default = _exports.default = {
    name: "custom-bbcodes-apply-kbd",
    initialize() {
      (0, _pluginApi.withPluginApi)('0.5', initializeKbd);
    }
  };
});